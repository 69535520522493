import { render, staticRenderFns } from "./PostgreSQL.vue?vue&type=template&id=560c9a87&scoped=true&"
import script from "./PostgreSQL.vue?vue&type=script&lang=js&"
export * from "./PostgreSQL.vue?vue&type=script&lang=js&"
import style0 from "./PostgreSQL.vue?vue&type=style&index=0&id=560c9a87&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560c9a87",
  null
  
)

export default component.exports