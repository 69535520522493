<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>PostgreSQL</h1>
        <p>Инструкция как настроить PostgreSQL</p>
      </div>
      <a class="btn" target="_blank" href="https://cp.sprinthost.ru/customer/boxes/list">Открыть хостинг</a>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>IP Address</label>
        <input v-model="ip" />
      </div>
      <div class="form-control">
        <label>Username</label>
        <input spellcheck="false" v-model="username" />
      </div>
      <div class="form-control">
        <label>DB Name</label>
        <input spellcheck="false" v-model="databaseName" />
      </div>
      <div class="form-control">
        <label>DB Password</label>
        <input spellcheck="false" v-model="databasePassword" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" class="action_result" tabindex="0">
      <template v-for="(node, n) of commandNodes">
        <pre :key="`${n}-${node.type}`" v-if="node.type === 'code'" v-highlightjs="node.content"><code class="bash"></code></pre>
        <p :key="`${n}-${node.type}`" v-if="node.type === 'text'" v-html="node.content" />
      </template>
    </div>
    <p class="next">Далее можно перейти к <router-link to="/services/nginx">настройке доменов</router-link></p>
  </section>
</template>

<script>
export default {
  name: 'Deployment',
  data() {
    return {
      ip: '185.185.68.125',
      username: 'yablonev',
      personalIP: '',
      databaseName: 'x_online',
      databasePassword: 'x_online_alpha',
    }
  },
  computed: {
    commands() {
      return [
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: `sudo apt update` },
        ],
        [
          { type: 'code', content: `sudo apt install postgresql postgresql-contrib` },
          { type: 'text', content: 'Нажать кнопку <span class="accent">«y»</span>' },
        ],
        [
          { type: 'code', content: `sudo systemctl start postgresql.service` },
        ],
        [
          { type: 'code', content: `sudo systemctl status postgresql` },
        ],
        [
          { type: 'code', content: `sudo ufw allow 5432` },
        ],
        [
          { type: 'code', content: `sudo ufw allow from ${this.personalIP}` },
        ],
        [
          { type: 'code', content: `sudo nano /etc/postgresql/10/main/postgresql.conf` },
          { type: 'text', content: `Поменяйте строчку на вот такую listen_addresses = '*'` }
        ],
        [
          { type: 'code', content: `sudo nano /etc/postgresql/10/main/pg_hba.conf` },
          { type: 'text', content: `Добавьте в конец файла строчки:` },
          { type: 'code', content: `# Custom
host     all     all     ${this.personalIP}/32     password` }
        ],
        [
          { type: 'code', content: `sudo systemctl restart postgresql` },
        ],
        [
          { type: 'code', content: `sudo -u postgres psql` },
          { type: 'text', content: `А затем делайте что вам нужно, например:` },
          { type: 'code', content: `ALTER ROLE postgres WITH LOGIN PASSWORD '${this.databasePassword}';

DROP DATABASE IF EXISTS ${this.databaseName};
CREATE DATABASE ${this.databaseName};` },
        ],
        [
          { type: 'text', content: `Чтобы выйти из psql` },
          { type: 'code', content: `\\q` },
        ],
        [
          { type: 'text', content: `Попробуйте подключится через Table Plus` },
          { type: 'code', content: `postgresql://postgres:${this.databasePassword}@${this.ip}:5432/${this.databaseName}` },
        ]
      ]
    }
  },
  methods: {
    async getIP() {
      const data = await fetch('https://api.ipify.org?format=json')
      const json = await data.json()
      return json.ip
    }
  },
  async mounted() {
    this.personalIP = await this.getIP()
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result:focus, .action_result:focus-visible
  box-shadow: inset 0 0 8px 2px #000000c2
  border: 0

.action_result p
  color: #4dbf90
  font-size: 14px
  line-height: 28px
  padding-left: 0.3em

p.next
  display: flex

  a
    color: #55657e
    padding-left: 4px
    font-weight: 500
</style>